<!-- eslint-disable -->
<template>
  <div class="box topic topicData">
    <div class="information-haeder-box">
      <div class="box-top">
        <div class="box-top-left">
          <div @click="goback" class="box-top-left-img">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          更多话题
        </div>
        <div class="box-top-right">
          <span>话题总数：{{ dataList.length }}</span>
          <span v-if="$store.state.user.scope[0]">境内：{{ jingnei.length }}</span>
          <span v-if="$store.state.user.scope[0] && $store.state.user.scope[2]">境内 + 跟评：{{ jingnei.length + genping.length
          }}</span>
          <span v-if="$store.state.user.scope[1]">境外：{{ jingwai.length }}</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/topics/index' }">话题分析</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 更多话题
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="box-content">
      <div class="box-seach">
        <div class="box-seach-left">
          <el-input clearable style="width: 240px; margin-right: 32px" placeholder="模糊搜索话题名称" prefix-icon="el-icon-search"
            v-model="seach.topicName"></el-input>
          <span class="text_title">结束时间</span>
          <el-date-picker value-format="yyyy-MM-dd" style="width: 280px; margin: 0 32px 0 16px" v-model="seach.upateTime"
            type="date" placeholder="选择日期"></el-date-picker>
          <span class="text_title">话题类型</span>
          <el-select class="topicData" style="width: 190px; margin-left: 12px" v-model="seach.topicType" placeholder="请选择"
            clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item"></el-option>
          </el-select>
        </div>
        <div class="box-seach-right">
          <el-button type="primary" @click="Search">查询</el-button>
          <el-button plain @click="Remake">重置</el-button>
        </div>
      </div>
      <div class="box-content-center">
        <div class="tags" v-if="tags != ''">
          <span class="tags-title">条件：</span>
          <el-tag v-for="tag in tags" closable @close="handleClose(tag, tags)" :key="tag.name" :type="tag.type">
            <span>{{ tag.text }}:</span>
            <span>{{ tag.value }}</span>
          </el-tag>
        </div>
        <el-table v-loading="loading" :data="tableData" stripe :header-cell-style="headerStyle" :cell-style="cellStyle"
          style="width: 100%; text-align: center; border: 1px solid #e4e6ec">
          <el-table-column label="序号" :index="indexMethod" type="index" width="120"></el-table-column>
          <el-table-column label="话题名称" width="300px" height="70px" style>
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" :content="scope.row.topicName" placement="top">
                <div class="name-wrapper" @click="handle(scope.row)">
                  {{ scope.row.topicName }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="话题类型">
            <template slot-scope="scope">
              <div>
                <span v-if="scope.row.topicType == 0">境内</span>
                <span v-if="scope.row.topicType == 1">境内+跟评</span>
                <span v-if="scope.row.topicType == 2">境外</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间"></el-table-column>
          <el-table-column label="结束时间">
            <template slot-scope="scope">
              <span>
                {{ scope.row.upateTime == null ? "当前" : scope.row.upateTime }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="242px">
            <template slot-scope="scope">
              <div>
                <el-button @click="
                  share({
                    topicId: scope.row.id,
                    organId: $store.state.user.organizationId,
                  })
                  " type="text" v-if="scope.row.childParentType == 1">
                  开始深度更新
                </el-button>
                <el-button @click="isUpdates(scope.row)" type="text" v-else>
                  开始深度更新
                </el-button>
                <el-link style="margin: 0 16px" type="info">|</el-link>
                <el-button style="color: #ea3342" class="shanchu" type="text" @click="
                  clientDeleteMsg({
                    topicId: scope.row.id,
                    organId: $store.state.user.organizationId,
                  })
                  " v-if="scope.row.childParentType == 1">删除</el-button>
                <el-button style="color: #ea3342" class="shanchu" type="text" @click="deletes(scope.row)"
                  v-else>删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <span class="total">
            共{{ tablePage.total }}条, 当前显示第{{
              tablePage.page * tablePage.pageSize - tablePage.pageSize + 1
            }}-
            {{
              tablePage.page * tablePage.pageSize > tablePage.total
              ? tablePage.total
              : tablePage.page * tablePage.pageSize
            }}条
          </span>
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="tablePage.page" :page-sizes="tablePage.pageSizes" :page-size="tablePage.pageSize"
            layout="sizes, prev, pager, next,jumper" :total="tablePage.total"></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="编辑" :visible.sync="add.dialogVisible" width="700px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialogcenter">
        <ul class="dialogcenter-top">
          <li style="width: 100%">
            <div class="dialogcenter-top-left">预警提醒</div>
            <el-radio-group v-model="add.radio">
              <el-radio :label="0" v-if="$store.state.user.scope[0]">境内</el-radio>
              <el-radio :label="1" v-if="$store.state.user.scope[0] && $store.state.user.scope[2]">境内+跟评</el-radio>
              <el-radio :label="2" v-if="$store.state.user.scope[1]">境外</el-radio>
            </el-radio-group>
          </li>
          <li>
            <div class="dialogcenter-top-left">话题名称</div>
            <el-input style="width: 100%" v-model="add.topicName" placeholder="请输入话题名称"></el-input>
          </li>
          <li>
            <div class="dialogcenter-top-left">开始时间</div>
            <el-date-picker style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss" v-model="add.startTime" type="datetime"
              placeholder="选择日期时间"></el-date-picker>
          </li>
          <li style="width: 100%">
            <div class="dialogcenter-top-left">主关键字</div>
            <div class="dialogcenter-top-right">
              <el-input v-model="add.mainKeyword" @keyup.enter.native="addUrl" placeholder="请添加主关键字，回车确认"></el-input>
              <el-button style="margin-left: 16px" @click="addUrl" type="primary">添 加</el-button>
            </div>
          </li>
          <li class="Listyleli" v-for="(val, inx) in add.mainKeywordList" :key="inx">
            <div class="Listyle">
              <span class="Listyle-left">{{ val }}</span>
              <img @click="deleteUrl(inx)" src="../../assets/img/views/monitoringScheme/XX.png" alt />
            </div>
          </li>
          <li style="width: 100%">
            <div class="dialogcenter-top-left">排除关键字</div>
            <el-input type="textarea" :rows="2" placeholder="请添加排除关键字" v-model="add.excludeKeyword"></el-input>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain type="primary" @click="add.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPreservation">保 存</el-button>
      </span>
    </el-dialog>
    <deleteDialog ref="openDialog" @deleteFn="deleteFn" />
  </div>
</template>

<script>
import {
  topicLists,
  editTopic, //修改话题的接口
  deletes,
  topicListData,
  listUpdate,
  share,
  clientDeleteMsg,
} from "../../api/topic/topic";
import deleteDialog from "../../components/deleteDialog";
export default {
  data() {
    return {
      tags: [],
      headerStyle: {
        background: "#F6F7FA",
        textAlign: "center",
        fontSize: "16px",
        color: "#333",
      },
      cellStyle: {
        textAlign: "center",
      },

      options: [],
      //页数
      tablePage: {
        pageSizes: [10, 20, 30, 40, 50],
        page: 1,
        pageSize: 10,
        total: 0,
      },
      currentPage: 1,
      tableData: [],
      loading: false,
      add: {
        dialogVisible: false,
        // 预警提醒
        radio: 0,
        // 话题名称
        topicName: "",
        // 开始时间
        startTime: "",
        // 主关键字
        mainKeyword: "",
        // 排除关键字
        excludeKeyword: "",
        mainKeywordList: [],
      },
      topicId: 0,
      // 表单筛选
      seach: {
        topicName: "",
        upateTime: "",
        topicType: "",
      },
      jingnei: [],
      jingwai: [],
      genping: [],
      // 话题总数
      dataList: [],
      searchData: {},
      //用于对比关键字组数组的长度，来判断是否编辑过关键字
      contrastList: [],
    };
  },
  methods: {
    //分享话题深度更新
    async share(data) {
      let res = await share(data);
      if (res.data.code == 200) {
        this.$message.success("深度更新成功!");
        this.topicList();
        this.topicListData();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    //分享的话题脱离关联关系
    async clientDeleteMsg(data) {
      let res = await clientDeleteMsg(data);
      if (res.data.code == 200) {
        this.topicList();
        this.topicListData();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 开始深度更新
    async isUpdates(val) {
      //  topicList
      const res = await listUpdate({
        organId: this.$store.state.user.organizationId,
        isDeepUpdate: 0, //0未关闭 1关闭
      });
      if (res.data.rows.length > this.$store.state.user.topicLimit - 1) {
        this.$message.warning("当前活动话题已达上限！请您关闭话题后再操作。");
      } else {
        const res = await editTopic({
          organId: this.$store.state.user.organizationId,
          topicId: val.topicId,
          userEdit: 0,
          isUpdate: 1,
          updateTypeId: val.isPause ? 1 : 0,
          isPause: 0,
          isDeepUpdate: 0,
          id: val.id,
        });
        if (res.data.code == 200) {
          this.$message.success("更新成功!");
        } else {
          this.$message.error("更新失败!");
        }
        this.topicList();
        this.topicListData();
      }
    },
    // 编辑
    lookDialog(val) {
      this.add.dialogVisible = true;
      this.topicId = val.topicId;
      this.add.radio = val.topicType;
      this.add.topicName = val.topicName;
      this.add.startTime = val.startTime;
      this.add.mainKeywordList = val.mainKeyword.split(",");
      this.contrastList = val.mainKeyword.split(",");
      this.add.excludeKeyword = val.excludeKeyword;
    },
    //删除url
    deleteUrl(inx) {
      this.add.mainKeywordList.splice(inx, 1);
    },
    handle(val) {
      window.open(
        `#/topics/index/topicList/topicListDetails?id=${val.id}&topicId=${val.topicId}&type=history&topicType=${val.topicType}&isDeepUpdate=${val.isDeepUpdate}&isPause=${val.isPause}`
      );
    },
    handleClose(tag, tags) {
      tags.splice(tags.indexOf(tag), 1);
      if (tag.text == "话题名称") {
        this.seach.topicName = "";
      } else if (tag.text == "更新时间") {
        this.seach.upateTime = "";
      } else {
        this.seach.topicType = "";
      }
      this.Search();
    },
    Remake() {
      this.searchData = {};
      this.tags = [];
      this.seach = {
        topicName: "",
        upateTime: "",
        topicType: "",
      };
      this.tablePage.page = 1;
      this.topicList();
    },
    // 删除
    deletes(val) {
      val.message = "此话题";
      this.$refs.openDialog.openDialog(val);
    },
    async deleteFn(item) {
      const res = await deletes({
        id: item.id,
      });
      if (res.data.code == 200) {
        this.$message({
          message: "删除成功!",
          type: "success",
        });
        if (this.tableData.length == 1) {
          this.tablePage.page = this.tablePage.page - 1;
        }
      } else {
        this.$message.error("删除失败！请稍后再试！");
      }
      this.topicList();
      this.topicListData();
    },
    // 保存
    async addPreservation() {
      if (this.add.topicName == "") {
        this.$message.warning("话题名称不能为空");
        return false;
      }
      if (this.add.startTime == null) {
        this.$message.warning("时间不能为空");
        return false;
      }
      if (this.add.startTime == "") {
        this.$message.warning("时间不能为空");
        return false;
      }
      if (this.add.mainKeywordList == "") {
        this.$message.warning("主关键字不能为空");
        return false;
      }
      for (let i = 0; i < this.add.mainKeywordList.length; i++) {
        if (this.add.mainKeywordList[i] == this.add.excludeKeyword) {
          return this.$message.warning("排除关键字不能和主关键字一致！");
        }
      }
      let res;
      let data = {
        topicType: this.add.radio,
        topicName: this.add.topicName,
        startTime: this.add.startTime + " " + "00:00:00",
        mainKeyword: this.add.mainKeywordList.join(","),
        excludeKeyword: this.add.excludeKeyword,
        organId: this.$store.state.user.organizationId,
        topicId: this.topicId,
        userEdit: 1,
        updateKeyState: 1,
      };
      res = await editTopic(data);
      this.endatist();

      if (res.data.code == 200) {
        this.$message({
          message: "编辑成功！",
          type: "success",
        });
      } else {
        this.$message.error("编辑失败！");
      }
      this.add.dialogVisible = false;
      this.topicListData();
    },
    async endatist() {
      await editTopic({
        topicId: this.topicId,
        organId: this.$store.state.user.organizationId,
        userEdit: 0,
        startTime: this.add.startTime + " " + "00:00:00",
      });
      this.topicList();
    },
    goback() {
      this.$router.push({
        path: "/topics/index",
      });
    },
    //搜索
    Search() {
      this.tags = [];
      this.tablePage.page = 1;
      if (this.seach.topicName != "") {
        this.tags.push({
          text: "话题名称",
          value: this.seach.topicName,
        });
      }
      if (this.seach.upateTime) {
        this.tags.push({
          text: "更新时间",
          value: this.seach.upateTime,
        });
      }
      if (this.seach.topicType != "") {
        this.tags.push({
          text: "选择话题",
          value: this.seach.topicType.value == 0 ? "境内话题" : "境内+跟评",
        });
      }

      let data = {};
      if (this.seach.topicName != "") {
        data.topicName = this.seach.topicName;
      }
      if (this.seach.upateTime) {
        data.upateTime = this.seach.upateTime + " " + "00:00:00";
      }
      if (this.seach.topicType != "") {
        data.topicType = this.seach.topicType.value;
      }

      this.topicList(data);
      this.topicListData();
    },
    //主关键字添加url
    addUrl() {
      this.add.mainKeyword = this.add.mainKeyword.trim();
      if (this.add.mainKeyword != "") {
        this.add.mainKeywordList.push(this.add.mainKeyword);
      }
      this.add.mainKeyword = "";
    },
    //  不带分页
    async topicListData() {
      this.jingnei = [];
      this.jingwai = [];
      this.genping = [];
      const res = await topicListData({
        isDeepUpdate: 1,
        organId: this.$store.state.user.organizationId,
      });
      if (res.data.rows) {
        this.dataList = res.data.rows;
        res.data.rows.forEach((item) => {
          if (item.topicType == 0) {
            this.jingnei.push(item);
          } else if (item.topicType == 1) {
            this.genping.push(item);
          } else if (item.topicType == 2) {
            this.jingwai.push(item);
          }
        });
      }
    },
    // 带分页列表查询
    async topicList(obj) {
      this.loading = true;
      let data = {
        organId: this.$store.state.user.organizationId,
        isDeepUpdate: 1,
      };
      if (obj) {
        this.searchData = obj;
        this.searchData.organId = this.$store.state.user.organizationId;
        this.searchData.isDeepUpdate = 1;
        data = this.searchData;
      } else if (Object.values(this.searchData).length > 0) {
        data = this.searchData;
      }
      let url = `?pageNum=${this.tablePage.page}&pageSize=${this.tablePage.pageSize}`;
      const res = await topicLists(data, url);
      if (res != undefined) {
        this.tableData = res.data.rows;
        this.tablePage.total = res.data.total;
      }
      this.loading = false;
    },
    // 分页
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.tablePage.page = 1;
      this.topicList();
      window.scrollTo(0, 0);
    },
    handleCurrentChange(val) {
      this.tablePage.page = val;
      this.topicList();
      window.scrollTo(0, 0);
    },
    // 序号
    indexMethod(index) {
      return (
        index +
        this.tablePage.page * this.tablePage.pageSize -
        this.tablePage.pageSize +
        1
      );
    },
  },
  created() {
    if (this.$store.state.user.scope[0]) {
      this.options.push({
        label: "境内",
        value: 0,
      });
    }
    if (this.$store.state.user.scope[0] && this.$store.state.user.scope[2]) {
      this.options.push({
        label: "境内+跟评",
        value: 1,
      });
    }
    if (this.$store.state.user.scope[1]) {
      this.options.push({
        label: "境外",
        value: 2,
      });
    }
    this.topicList();
    this.topicListData();
  },
  components: {
    deleteDialog,
  },
};
</script>

<style lang="scss" scoped>
.box {
  .breadcrumb {
    margin-top: 77px;
    margin-left: 24px;
  }

  .dialogcenter {
    .dialogcenter-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      li {
        width: 48%;
        margin-bottom: 24px;

        .dialogcenter-top-left {
          margin-bottom: 16px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }

        .dialogcenter-top-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .Listyle {
          height: 32px;
          background: #f5f6fa;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;

          .Listyle-left {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          img {
            padding-left: 16px;
            cursor: pointer;
          }
        }
      }

      .Listyleli {
        width: 100%;
      }
    }
  }

  .box-top {
    width: 100%s;
    height: 56px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .box-top-left {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: #333333;

      .box-top-left-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: 1px solid #cccccc;
        border-radius: 50%;
        cursor: pointer;
        margin-right: 16px;
      }
    }

    .box-top-right {
      span {
        font-size: 14px;
        color: #333333;
        margin-left: 15px;
      }
    }
  }

  .box-content {
    padding: 0 24px 24px 24px;

    .box-seach {
      padding: 16px 24px;
      width: 100%;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .box-seach-left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text_title {
          font-size: 14px;
          font-weight: 600;
          color: #333333;
        }
      }

      .box-seach-right {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ::v-deep .el-button {
          width: 60px;
          height: 32px;
          border-radius: 4px;
          padding: 0;
        }
      }
    }

    .box-content-center {
      margin-top: 16px;
      padding: 8px 24px 24px 24px;
      width: 100%;
      background: #ffffff;
      border-radius: 0px 0px 4px 4px;

      .box-content-center-seach {
        width: 100%;
        padding: 9px 16px;
        background: #f2f8ff;
        border-radius: 4px;
        border: 1px solid #89a4ff;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .box-content-center-seach-tag {
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #ced5f1;
          padding: 1px 8px;
          display: flex;
          align-items: center;
          margin-left: 8px;

          span {
            font-size: 12px;
            color: #2e59ec;
          }

          p {
            margin-left: 11px;
            font-size: 16px;
            cursor: pointer;
            color: #ea3342;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .name-wrapper {
        cursor: pointer;
        color: #2e59ec;
      }

      .block {
        position: relative;
      }

      .block-count {
        position: absolute;
        top: 7px;
        font-size: 14px;
        color: #333333;
        left: 0;
      }

      .block .total {
        position: absolute;
        bottom: 5%;
        left: 7%;
        z-index: 11;
        font-size: 14px;
        display: inline-block;
        line-height: 32px;
        color: #999999;
      }
    }
  }

  ::v-deep .el-breadcrumb__separator {
    margin: 0 2px;
  }

  .el-table {
    margin-top: 16px;
  }
}
</style>
